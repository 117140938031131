.nav-main {
  background-color: #f8f9fa;
}
.navbar {
  max-width: 1920px;
  margin: auto;
}
.navspace {
  position: relative;
  left: 50px;
  padding: 5px 5px;
  /* border: 2px solid red; */
}
.lang {
  position: relative;
  left: 30px;
}
.nav-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.search-div {
  width: 370px;
  position: relative;
}

#navspace-Login {
  position: absolute;
  right: 80px;
  padding: 5px 20px;
  border: var(--Button-color, #2877ff);
}
#navspace-Register {
  position: absolute;
  right: 0px;
  padding: 5px 5px;
}
.container-fluid img {
  position: relative;
  left: 0px;
}
.test {
  display: flex;
  justify-content: center;
}
.search-link {
  width: 800px;
  display: flex;
  justify-content: center;
}
.search-link .search-Dropdown {
  /* border: 2px solid red; */
  flex-grow: 3;
  padding: 10px;
}
.search-link .ul-custom {
  flex-grow: 4;
  padding: 10px;
}
.log-link {
  width: 400px;
  display: flex;
  position: relative;
  left: -30px;
  justify-content: center;
}

.ul-custom {
  /* border: 2px solid red; */
  position: relative;
  left: 10%;
  /* flex-grow: 3; */
  width: 430px;
}
.ul-custom li {
  /* border: 2px solid red; */
  padding: 0px;
  width: 120px;
}
.ul-custom li button {
  transition: 0.4s ease-in-out;
  font-weight: 350;
  font-size: 15px;
}

.ul-custom li a {
  width: 110px;
  transition: 0.4s ease-in-out;
  font-weight: 350;
  font-size: 15px;
}

.form-custom {
  margin: 0px 10px;
}
.custom-1 {
  margin-left: 50px;
}
.search-Dropdown {
  /* border: 2px solid red; */
  flex-grow: 4;
  max-width: 400px;
  min-width: 187px;
  position: relative;
  left: 20px;
  cursor: text;
}

.search-Dropdown div {
  cursor: text;
}

.landing {
  margin: auto;
  max-width: 1920px;
}
.page-content {
  /* border: 2px solid red; */
  padding: 0px 80px;
}

.langbtn {
  position: relative;
  left: 20px;
}
.navtext {
  color: var(--Text-color, #292e32);
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.nav-item {
  padding: 0px 20px;
  /* border: 2px solid red; */
}
/* .basic-single{
  border: 2px solid black;
  border-radius: 20px;
} */
.nav-resp-logo {
  display: none;
}
.nav-resp-logo2 {
  display: block;
}

.nav-resp-logo img{
  height: 60px;
}
.nav-resp-logo2 img{
  height: 60px;
  position: relative;
  top: 5px;
}
.btn-group {
  display: none;
}
#lap {
  display: block;
  position: relative;
  right: 10px;
}
#phone2 {
  display: none;
}

@media screen and (max-width: 1160px) {
  .nav-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .nav-flex-container-1 {
    position: relative;
    left: 63px;
  }
  .navspace {
    position: relative;
    /* left: 50px; */
    padding: 5px 5px;
  }

  .log-link {
    position: relative;
    width: 170px;
    left: -80px;
  }
  .container-fluid img {
    /* border: 2px solid red; */
    position: relative;
    left: 0px;
  }
}
@media screen and (max-width: 991px) {
  #lap {
    display: none;
  }
  #phone2 {
    display: block;
    position: relative;
    left: 55px;
    bottom: 30px;
  }
  .nav-resp-logo {
    display: block;
  }
  .nav-resp-logo2 {
    display: none;
  }
  .search-link {
    display: block;
  }
  .langbtn {
    position: relative;
    left: 00px;
    /* bottom:175px; */
  }
  .test {
    display: block;
  }
  .navspace-Login {
    position: relative;
    left: 23px;
    border: var(--Button-color, #2877ff);
  }
  .navspace-Register {
    position: relative;
    left: 50px;
  }
  .nav-flex-container-1 {
    position: relative;
    left: 0px;
  }
  .search-div {
    margin: 10px 0px;
    width: 100%;
  }
  .ul-custom {
    position: relative;
    left: 00px;
    height: 250px;
  }
  .custom-1 {
    position: absolute;
    bottom: 125px;
    left: 40px;
  }
  .custom-2 {
    position: absolute;
    bottom: 80px;
    left: 80px;
  }
  .search-Dropdown {
    width: 60px;
    position: relative;
    left: 0px;
    cursor: text;
    /* border: 2px solid red; */
  }
  .nav-item {
    padding: 0px 20px;
    height: 35px;
  }
  .navtext {
    color: var(--Text-color, #292e32);
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
  }
   
  .lang-div-component{
    position: relative;
    left: 65px;
    bottom: 20px;
    z-index: 100;

  }
}

@media screen and (max-width: 500px) {
  .page-content {
    /* border: 2px solid red; */
    padding: 0px 20px;
  }
}

